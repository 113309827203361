<!-- 政策详情 了解完可以直接点申请-->

<template>
  <div class="details centebox">
    <el-menu router :default-active="(index = '/declarewz')" />
    <div class="heasd">
      <span @click="back" style="cursor: pointer">
        <i class="el-icon-arrow-left" style="margin: 0 8px"></i>政策详情
      </span>
    </div>
    <div class="tiel">
      <el-row :gutter="0">
        <el-col :span="4" class="timetit">
          <div class="bg-purple tiels">申报政策：</div>
        </el-col>
        <el-col :span="20" class="timecom">
          <div class="bg-purple" v-html="contentData.title"></div>
        </el-col>
      </el-row>
      <el-row :gutter="0">
        <el-col :span="4" class="timetit">
          <div class="bg-purple tiels">申报时间：</div>
        </el-col>
        <el-col :span="20" class="timecom">
          <div class="bg-purple">
            {{ contentData.startTime }} -至-
            {{ contentData.expireTime }}

            <span v-if="enddays > 0" type="success">
              <b style="color: #67c23a"> 正在申报</b>
            </span>

            <span v-else style="margin-left: 16px"
              ><b style="color: #E6A23C">申报已经结束！</b></span
            >
          </div>
        </el-col>
      </el-row>
    </div>

    <h2>
      {{ contentData.title }}
      <p>
        <span>发布日期：{{ clobDO.createTime | formatDate }}</span>
        <span v-if="contentData.type === 'enterprise'">类型：单位申报</span>
        <span v-else-if="contentData.type === 'talent'">类型：个人申报</span>
        <span v-else
          >类型：<el-tag type="warning">类型有误，不能申报！</el-tag></span
        >
      </p>
    </h2>

    <div class="pages_content" v-html="clobDO.content"></div>

    <div class="pages_content">
      <ul class="filess">
        <li
          v-for="(item, index) in files"
          :key="index"
          @click="downloaded(item.path)"
        >
          <i class="el-icon-folder-opened"></i> {{ item.name }}
        </li>
      </ul>
    </div>

    <el-row>
      <div v-if="enddays > 0">
        <template v-if="leix">
          <template v-if="disabled">
            <el-button type="primary" @click="declare" class="text"
              >申报</el-button
            ><br />
            <el-button type="text" v-show="!IsDisabled"
              >申报已经超过次数！</el-button
            >
          </template>
          <!-- IsDisabled -->
          <template v-else>
            <el-tag type="warning"><b>请等待开放申报！</b></el-tag>
          </template>
        </template>
        <!--                v-if="item.status==1"-->
        <el-tag type="warning" v-else>请先登录！</el-tag>
      </div>
      <div v-else-if="contentData.status === 0">
        <el-tag type="warning"><b>请等待开放申报！</b></el-tag>
      </div>
      <div v-else>
        <el-tag type="warning"><b>申报已经结束！</b></el-tag>
      </div>

      <div class="hr-10"></div>
    </el-row>
  </div>
</template>

<script>
import Moment from "moment";
import configDate from "@/utils/config";
export default {
  data() {
    return {
      fileurl: configDate.fileurl,
      leix: sessionStorage.getItem("Leix") || "",
      disabled: false,
      id: sessionStorage.getItem("ID"),
      enddays: "",
      contentData: "",
      clobDO: {},
      files: [],
      IsDisabled: true,
      //Columnname:''
    };
  },
  //用于数据初始化
  created() {
    this.Getcontent(); //获取详情
  },
  //用于存放所有的事件方法集合
  methods: {
    back() {
      this.$router.push({ name: "declareitem" });
    },
    Getcontent() {
      var self = this;
      this.$axios
        .get("/policy/annex", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then(function(res) {
          self.files = res.data.data.files;
          self.contentData = res.data.data;

          self.disabled = res.data.data.status !== 0;
          self.clobDO = res.data.data.clob;
          console.log(self.contentData);
          self.Frequency(); //检查还剩次数
          self.enddays = Moment(self.contentData.expireTime).diff(
            Moment(),
            "minute"
          );

          self.enddays = self.enddays / 24 / 60;
          console.log(self.enddays);
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    //下载
    downloaded(path) {
      window.location.href = this.fileurl + path;
    },
    islogin() {
      this.$message.error("请先登录账号！");
    },
    //判断是否超出申报次数
    Frequency() {
      var self = this;
      this.$axios
        .get("/affair/judgmentFrequency", {
          params: {
            policyTypeId: self.contentData.applyTimes,
            userId: self.id,
          },
        })
        .then(function(res) {
          self.IsDisabled = res.data.data;
        });
    },

    //进入申报
    declare() {
      var self = this;
      if (!self.IsDisabled) {
        self.$message.error("申报已经超过次数！");
        return;
      }

      this.$axios
        .get("/policy/annex?id=" + this.$route.params.id)
        .then(function(res) {
          if (res.data.data.type) {
            if (res.data.data.type === self.leix) {
              if (self.leix === "talent") {
                self.$router.push({
                  path: "/declarefrom/" + self.$route.params.id,
                });
              } else {
                self.$router.push({
                  path: "/qydeclarefrom/" + self.$route.params.id,
                });
              }
            } else {
              self.disabled = true;
              self.$message.error("类型不同不能申报");
            }
          } else {
            self.$message.error("类型为空");
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.details {
  background: #fff;
  max-width: 1200px;
  min-height: 456px;
  margin-bottom: 8px;

  .tiel {
    max-width: 1150px;
    margin: 10px auto;
    border: 1px #ddd solid;
    border-bottom: 0;

    .bg-purple {
      height: 45px;
      line-height: 45px;
      border-bottom: 1px #ddd solid;
      text-align: left;
      padding: 0 0 0 8px;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      overflow: hidden;
    }

    .tiels {
      border-right: 1px #ddd solid;
      padding: 0;
      background: #9ed1ff;
      text-align: center;
      color: #2380d7;
    }
  }

  h2 {
    margin: 0 auto;
    max-width: 1140px;
    padding: 15px 0 0 0;
    line-height: 45px;
    border-bottom: 1px #ddd solid;
    color: #2380d7;

    p {
      padding: 0;
      margin: 0;
      height: 65px;
      line-height: 70px;
      color: #333;

      span {
        font-size: 16px;
        font-weight: 500;
        padding: 0 15px;
      }
    }
  }

  .pages_content {
    padding: 20px;
    text-align: left;
    line-height: 36px;
  }
}
.filess {
  li {
    cursor: pointer;
    color: #0c94ea;
  }
}
.heasd {
  height: 40px;
  border-bottom: 1px #ddd solid;
  text-align: left;
  line-height: 40px;
  padding: 0 0 0 16px;
  color: #2380d7;
  font-weight: bold;
}
.text {
  color: #ffffff;
}
@import "../../styles/common_responsive.less";
</style>
